<template>
  <div id="app">
    <van-nav-bar title="工装详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="common-wrap">
      <van-form>
        <van-field v-model="info.coding" label="编码" />
        <van-field v-model="info.name" label="名称" />
        <van-field v-model="info.drawing_number" label="图号" />
        <van-field v-model="jigUnit[info.unit]" label="单位" />
        <van-field v-model="info.number" label="数量" />
        <van-field v-model="info.original_value" label="工装原值（万元）" />
        <van-field v-model="info.start_date" label="投产时间" />
        <van-field v-model="info.weight" label="重量(吨)" />
        <van-field v-model="info.length" label="长" />
        <van-field v-model="info.width" label="宽" />
        <van-field v-model="info.height" label="高" />
        <van-field v-model="info.product_name" label="加工品名称" />
        <van-field v-model="info.product_coding" label="加工品零件号" />
        <van-field v-model="info.process_coding" label="对应工序号" />
        <van-field v-model="info.manufacturer" label="工装制造商" />
        <van-field v-model="eqStatus[info.status]" label="状态" />
      </van-form>
    </div>
  </div>
</template>
<script>
import { jigUnit, eqStatus } from  '@/utils/options'
export default {
  data() {
    return {
      info: {
      },
      jigUnit,
      eqStatus,
    }
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      const res = {
        coding: 'V85V30090300000204',
        name: '焊夹-左顶盖总成',
        drawing_number: 'P80-6145',
        unit: 1,
        number: 1,
        original_value: '85470.09',
        start_date: '2016-11-11',
        weight: '1',
        length: '2',
        width: '3',
        height: '4',
        product_name: '左顶盖总成夹具',
        product_coding: '5701025-B45',
        process_coding: 1,
        manufacturer: '一汽模具',
        status: 1
      }
      this.info = res
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>

</style>
